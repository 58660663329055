import { Subject } from 'rxjs';
import { ContentBlock } from './content-block';
import { Component } from '@angular/core';

@Component({ template: `` })
export class ContentBlockEditorComponent<BlockT extends ContentBlock = ContentBlock> {
    private _block: BlockT;

    get block(): BlockT {
        return this._block;
    }

    set block(value) {
        this._block = value;
        this._knownBlock = JSON.stringify(value);
        setTimeout(() => this.onBlockChanged());
    }

    private _knownBlock: string;

    ngDoCheck() {
        let blockJson = JSON.stringify(this.block);
        if (this._knownBlock !== blockJson) {
            this._knownBlock = blockJson;
            setTimeout(() => this.onBlockChanged());
        }
    }

    private _delayedAutoSave = new Subject<void>();
    readonly delayedAutoSave = this._delayedAutoSave.asObservable();

    locked: boolean;

    protected onBlockChanged() {

    }

    protected delayAutoSave() {
        this._delayedAutoSave.next();
    }
}
